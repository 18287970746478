import './App.css';
import {useRoutes} from 'react-router-dom';
import Main from './pages/Main/Main';
import Header from './components/Header/index';
import SignPageHeader from './components/SignPageHeader/SignPageHeader';
import Register from './pages/Register/Register';
import Market from './pages/Market/Market';
import Chart from './pages/Chart/Chart';

function App() {
  
  let element = useRoutes([
    {
      path:'', 
      element:<Header/>,
      children:[
        { path: '', element: <Main /> },
        { path: 'market', element: <Market /> },
        { path: 'chart', element: <Chart /> },
      ]
    },
    {
      path:'', 
      element:<SignPageHeader/>,
      children:[
        { path: 'sign-up', element: <Register route={'register'}/> },
        { path: 'sign-in', element: <Register route={'login'}/> },
      ]
    },
    // {
    //   path:'',
    //   element:<Auth/>,
    //   children:[
    //     { path: '', element: <Login /> },
    //     { path: 'forgot', element: <Forgot /> },
    //     { path: 'register', element: <Register /> },
    //     // { path: 'reset', element: <Reset /> },
    //     { path: 'reset/:id', element: <Reset /> },
    //     { path: 'join/:id/:token', element: <JoinGame /> },
    //   ]
    // },
  ]);

  return element;
  
}

export default App;
