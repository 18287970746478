import { useState } from "react"
import { TableDataDto } from "../dto/tableData.dto"
import style from './style.module.css'
import link from './notactive.png'
import unlink from './active.png'
import arrows from './arrows.png'
import IconButton from '@mui/material/IconButton'
import { useMediaQuery } from "@mui/material"
export default function Table(){
  const [data, setData] = useState<TableDataDto[]>([
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
    {id:1,name:'SAND/BNB',price:'0.007299/$4.03', changes:'12.14%',position:'0.008774 / 0.006572', volume:'41.200', cap:'3595.75M',edit:'Trade'},
  ])

  

  const isMobilecard = useMediaQuery("(max-width:830px)");
  return(
    <div>
      <table className={style.table}>
      <thead>
        <tr>
          {!isMobilecard&&<th><div>Name <img src={arrows} alt="arrows" /></div></th>}
          <th><div>Price<img src={arrows} alt="arrows" /></div></th>
          <th><div>24h Changes<img src={arrows} alt="arrows" /></div></th>
          {!isMobilecard&&<th><div>24h Height / 24h Low</div></th>}
          <th><div>24h Volume<img src={arrows} alt="arrows" /></div></th>
          {!isMobilecard&&<th><div>Market Cap<img src={arrows} alt="arrows" /></div></th>}
          {!isMobilecard&&<th><div>Edit<img src={arrows} alt="arrows" /></div></th>}
        </tr>
       </thead>
       <tbody>
         {
          data.map(item=>{
            return(
              <tr key={item.id} className={style.trow}>
                {!isMobilecard&&<td><div><IconButton><img src={link} alt='link'/></IconButton>{item.name}</div></td>}
                <td><div>{item.price}</div></td>
                <td><div>{item.changes}</div></td>
                {!isMobilecard&&<td><div>{item.position}</div></td>}
                <td><div>{item.volume}</div></td>
                {!isMobilecard&&<td><div>{item.cap}</div></td>}
                {!isMobilecard&&<td><div>{item.edit}</div></td>}
              </tr>
            )
          })
         }
         
         <tr>
         </tr>
      </tbody>
    </table>
    </div>
  )
}