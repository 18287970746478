import { Typography } from '@mui/material'
import style from './style.module.css'
export default function CaptionCourse(){
    const data = [
        {id:1,curr:'ETH/BUSD', percent:2.81,pr1:'4,723.32', pr2:'4,723.32'},
        {id:2,curr:'ETH/BUSD', percent:2.81,pr1:'4,723.32', pr2:'4,723.32'},
        {id:3,curr:'ETH/BUSD', percent:2.81,pr1:'4,723.32', pr2:'4,723.32'},
        {id:4,curr:'ETH/BUSD', percent:2.81,pr1:'4,723.32', pr2:'4,723.32'},
        // {id:5,curr:'ETH/BUSD', percent:2.81,pr1:'4,723.32', pr2:'4,723.32'},
    ]
    return(
        <div className={style.course}>
            {
                data.map(item=>{
                    return(
                        <div className={style.course_item} key={item.id}>
                            <Typography variant={'body1'}>{item.curr} <span style={{color:'rgba(47, 167, 99, 1)'}}>{item.percent}%</span></Typography>
                            <Typography variant={'subtitle2'}>{item.pr1}</Typography>
                            <Typography variant={'caption'}>{item.pr2}$</Typography>
                        </div>
                    )
                })
            }
        </div>
    )
}