export const data0 = [
  [
      1575383400000,
      64.58,
      64.88,
      64.07,
      64.86,
      114430400
  ],
  [
      1575469800000,
      65.27,
      65.83,
      65.17,
      65.43,
      67181600
  ],
  [
      1575556200000,
      65.95,
      66.47,
      65.68,
      66.39,
      74424400
  ],
  [
      1575642600000,
      66.87,
      67.75,
      66.82,
      67.68,
      106075600
  ],
  [
      1575901800000,
      67.5,
      67.7,
      66.23,
      66.73,
      128042400
  ],
  [
      1575988200000,
      67.15,
      67.52,
      66.46,
      67.12,
      90420400
  ],
  [
      1576074600000,
      67.2,
      67.78,
      67.13,
      67.69,
      78756800
  ],
  [
      1576161000000,
      66.94,
      68.14,
      66.83,
      67.86,
      137310400
  ],
  [
      1576247400000,
      67.86,
      68.82,
      67.73,
      68.79,
      133587600
  ],
  [
      1576506600000,
      69.25,
      70.2,
      69.25,
      69.96,
      128186000
  ],
  [
      1576593000000,
      69.89,
      70.44,
      69.7,
      70.1,
      114158400
  ],
  [
      1576679400000,
      69.95,
      70.47,
      69.78,
      69.93,
      116028400
  ],
  [
      1576765800000,
      69.88,
      70.29,
      69.74,
      70,
      98369200
  ],
  [
      1576852200000,
      70.56,
      70.66,
      69.64,
      69.86,
      275978000
  ],
  [
      1577111400000,
      70.13,
      71.06,
      70.09,
      71,
      98572000
  ],
  [
      1577197800000,
      71.17,
      71.22,
      70.73,
      71.07,
      48478800
  ],
  [
      1577370600000,
      71.21,
      72.5,
      71.18,
      72.48,
      93121200
  ],
  [
      1577457000000,
      72.78,
      73.49,
      72.03,
      72.45,
      146266000
  ],
  [
      1577716200000,
      72.36,
      73.17,
      71.31,
      72.88,
      144114400
  ],
  [
      1577802600000,
      72.48,
      73.42,
      72.38,
      73.41,
      100805600
  ],
  [
      1577975400000,
      74.06,
      75.15,
      73.8,
      75.09,
      135480400
  ],
  [
      1578061800000,
      74.29,
      75.14,
      74.13,
      74.36,
      146322800
  ],
  [
      1578321000000,
      73.45,
      74.99,
      73.19,
      74.95,
      118387200
  ],
  [
      1578407400000,
      74.96,
      75.22,
      74.37,
      74.6,
      108872000
  ],
  [
      1578493800000,
      74.29,
      76.11,
      74.29,
      75.8,
      132079200
  ],
  [
      1578580200000,
      76.81,
      77.61,
      76.55,
      77.41,
      170108400
  ],
  [
      1578666600000,
      77.65,
      78.17,
      77.06,
      77.58,
      140644800
  ],
  [
      1578925800000,
      77.91,
      79.27,
      77.79,
      79.24,
      121532000
  ],
  [
      1579012200000,
      79.18,
      79.39,
      78.04,
      78.17,
      161954400
  ],
  [
      1579098600000,
      77.96,
      78.88,
      77.39,
      77.83,
      121923600
  ],
  [
      1579185000000,
      78.4,
      78.93,
      78.02,
      78.81,
      108829200
  ],
  [
      1579271400000,
      79.07,
      79.68,
      78.75,
      79.68,
      137816400
  ],
  [
      1579617000000,
      79.3,
      79.75,
      79,
      79.14,
      110843200
  ],
  [
      1579703400000,
      79.64,
      80,
      79.33,
      79.43,
      101832400
  ],
  [
      1579789800000,
      79.48,
      79.89,
      78.91,
      79.81,
      104472000
  ],
  [
      1579876200000,
      80.06,
      80.83,
      79.38,
      79.58,
      146537600
  ],
  [
      1580135400000,
      77.51,
      77.94,
      76.22,
      77.24,
      161940000
  ],
  [
      1580221800000,
      78.15,
      79.6,
      78.05,
      79.42,
      162234000
  ],
  [
      1580308200000,
      81.11,
      81.96,
      80.35,
      81.08,
      216229200
  ],
  [
      1580394600000,
      80.14,
      81.02,
      79.69,
      80.97,
      126743200
  ],
  [
      1580481000000,
      80.23,
      80.67,
      77.07,
      77.38,
      199588400
  ],
  [
      1580740200000,
      76.07,
      78.37,
      75.56,
      77.17,
      173788400
  ],
  [
      1580826600000,
      78.83,
      79.91,
      78.41,
      79.71,
      136616400
  ],
  [
      1580913000000,
      80.88,
      81.19,
      79.74,
      80.36,
      118826800
  ],
  [
      1580999400000,
      80.64,
      81.31,
      80.07,
      81.3,
      105425600
  ],
  [
      1581085800000,
      80.59,
      80.85,
      79.5,
      80.01,
      117684000
  ],
  [
      1581345000000,
      78.54,
      80.39,
      78.46,
      80.39,
      109348800
  ],
  [
      1581431400000,
      80.9,
      80.97,
      79.68,
      79.9,
      94323200
  ],
  [
      1581517800000,
      80.37,
      81.81,
      80.37,
      81.8,
      113730400
  ],
  [
      1581604200000,
      81.05,
      81.56,
      80.84,
      81.22,
      94747600
  ],
  [
      1581690600000,
      81.18,
      81.5,
      80.71,
      81.24,
      80113600
  ],
  [
      1582036200000,
      78.84,
      79.94,
      78.65,
      79.75,
      152531200
  ],
  [
      1582122600000,
      80,
      81.14,
      80,
      80.9,
      93984000
  ],
  [
      1582209000000,
      80.66,
      81.16,
      79.55,
      80.07,
      100566000
  ],
  [
      1582295400000,
      79.65,
      80.11,
      77.63,
      78.26,
      129554000
  ],
  [
      1582554600000,
      74.32,
      76.04,
      72.31,
      74.54,
      222195200
  ],
  [
      1582641000000,
      75.24,
      75.63,
      71.53,
      72.02,
      230673600
  ],
  [
      1582727400000,
      71.63,
      74.47,
      71.63,
      73.16,
      198054800
  ],
  [
      1582813800000,
      70.28,
      71.5,
      68.24,
      68.38,
      320605600
  ],
  [
      1582900200000,
      64.32,
      69.6,
      64.09,
      68.34,
      426510000
  ],
  [
      1583159400000,
      70.57,
      75.36,
      69.43,
      74.7,
      341397200
  ],
  [
      1583245800000,
      75.92,
      76,
      71.45,
      72.33,
      319475600
  ],
  [
      1583332200000,
      74.11,
      75.85,
      73.28,
      75.68,
      219178400
  ],
  [
      1583418600000,
      73.88,
      74.89,
      72.85,
      73.23,
      187572800
  ],
  [
      1583505000000,
      70.5,
      72.71,
      70.31,
      72.26,
      226176800
  ],
  [
      1583760600000,
      65.94,
      69.52,
      65.75,
      66.54,
      286744800
  ],
  [
      1583847000000,
      69.29,
      71.61,
      67.34,
      71.33,
      285290000
  ],
  [
      1583933400000,
      69.35,
      70.31,
      67.96,
      68.86,
      255598800
  ],
  [
      1584019800000,
      63.99,
      67.5,
      62,
      62.06,
      418474000
  ],
  [
      1584106200000,
      66.22,
      69.98,
      63.24,
      69.49,
      370732000
  ],
  [
      1584365400000,
      60.49,
      64.77,
      60,
      60.55,
      322423600
  ],
  [
      1584451800000,
      61.88,
      64.4,
      59.6,
      63.22,
      324056000
  ],
  [
      1584538200000,
      59.94,
      62.5,
      59.28,
      61.67,
      300233600
  ],
  [
      1584624600000,
      61.85,
      63.21,
      60.65,
      61.19,
      271857200
  ],
  [
      1584711000000,
      61.79,
      62.96,
      57,
      57.31,
      401693200
  ],
  [
      1584970200000,
      57.02,
      57.13,
      53.15,
      56.09,
      336752800
  ],
  [
      1585056600000,
      59.09,
      61.92,
      58.58,
      61.72,
      287531200
  ],
  [
      1585143000000,
      62.69,
      64.56,
      61.08,
      61.38,
      303602000
  ],
  [
      1585229400000,
      61.63,
      64.67,
      61.59,
      64.61,
      252087200
  ],
  [
      1585315800000,
      63.19,
      63.97,
      61.76,
      61.94,
      204216800
  ],
  [
      1585575000000,
      62.69,
      63.88,
      62.35,
      63.7,
      167976400
  ],
  [
      1585661400000,
      63.9,
      65.62,
      63,
      63.57,
      197002000
  ],
  [
      1585747800000,
      61.63,
      62.18,
      59.78,
      60.23,
      176218400
  ],
  [
      1585834200000,
      60.08,
      61.29,
      59.22,
      61.23,
      165934000
  ],
  [
      1585920600000,
      60.7,
      61.42,
      59.74,
      60.35,
      129880000
  ],
  [
      1586179800000,
      62.72,
      65.78,
      62.35,
      65.62,
      201820400
  ],
  [
      1586266200000,
      67.7,
      67.93,
      64.75,
      64.86,
      202887200
  ],
  [
      1586352600000,
      65.68,
      66.84,
      65.31,
      66.52,
      168895200
  ],
  [
      1586439000000,
      67.18,
      67.52,
      66.18,
      67,
      161834800
  ],
  [
      1586784600000,
      67.08,
      68.43,
      66.46,
      68.31,
      131022800
  ],
  [
      1586871000000,
      70,
      72.06,
      69.51,
      71.76,
      194994800
  ],
  [
      1586957400000,
      70.6,
      71.58,
      70.16,
      71.11,
      131154400
  ],
  [
      1587043800000,
      71.85,
      72.05,
      70.59,
      71.67,
      157125200
  ],
  [
      1587130200000,
      71.17,
      71.74,
      69.21,
      70.7,
      215250000
  ],
  [
      1587389400000,
      69.49,
      70.42,
      69.21,
      69.23,
      130015200
  ],
  [
      1587475800000,
      69.07,
      69.31,
      66.36,
      67.09,
      180991600
  ],
  [
      1587562200000,
      68.4,
      69.47,
      68.05,
      69.03,
      116862400
  ],
  [
      1587648600000,
      68.97,
      70.44,
      68.72,
      68.76,
      124814400
  ],
  [
      1587735000000,
      69.3,
      70.75,
      69.25,
      70.74,
      126161200
  ],
  [
      1587994200000,
      70.45,
      71.14,
      69.99,
      70.79,
      117087600
  ],
  [
      1588080600000,
      71.27,
      71.46,
      69.55,
      69.64,
      112004800
  ],
  [
      1588167000000,
      71.18,
      72.42,
      70.97,
      71.93,
      137280800
  ],
  [
      1588253400000,
      72.49,
      73.63,
      72.09,
      73.45,
      183064000
  ],
  [
      1588339800000,
      71.56,
      74.75,
      71.46,
      72.27,
      240616800
  ],
  [
      1588599000000,
      72.29,
      73.42,
      71.58,
      73.29,
      133568000
  ],
  [
      1588685400000,
      73.76,
      75.25,
      73.61,
      74.39,
      147751200
  ],
  [
      1588771800000,
      75.11,
      75.81,
      74.72,
      75.16,
      142333600
  ],
  [
      1588858200000,
      75.81,
      76.29,
      75.49,
      75.93,
      115215200
  ],
  [
      1588944600000,
      76.41,
      77.59,
      76.07,
      77.53,
      133838400
  ],
  [
      1589203800000,
      77.03,
      79.26,
      76.81,
      78.75,
      145946400
  ],
  [
      1589290200000,
      79.46,
      79.92,
      77.73,
      77.85,
      162301200
  ],
  [
      1589376600000,
      78.04,
      78.99,
      75.8,
      76.91,
      200622400
  ],
  [
      1589463000000,
      76.13,
      77.45,
      75.38,
      77.39,
      158929200
  ],
  [
      1589549400000,
      75.09,
      76.97,
      75.05,
      76.93,
      166348400
  ],
  [
      1589808600000,
      78.29,
      79.13,
      77.58,
      78.74,
      135178400
  ],
  [
      1589895000000,
      78.76,
      79.63,
      78.25,
      78.29,
      101729600
  ],
  [
      1589981400000,
      79.17,
      79.88,
      79.13,
      79.81,
      111504800
  ],
  [
      1590067800000,
      79.67,
      80.22,
      78.97,
      79.21,
      102688800
  ],
  [
      1590154200000,
      78.94,
      79.81,
      78.84,
      79.72,
      81803200
  ],
  [
      1590499800000,
      80.88,
      81.06,
      79.13,
      79.18,
      125522000
  ],
  [
      1590586200000,
      79.04,
      79.68,
      78.27,
      79.53,
      112945200
  ],
  [
      1590672600000,
      79.19,
      80.86,
      78.91,
      79.56,
      133560800
  ],
  [
      1590759000000,
      79.81,
      80.29,
      79.12,
      79.49,
      153532400
  ],
  [
      1591018200000,
      79.44,
      80.59,
      79.3,
      80.46,
      80791200
  ],
  [
      1591104600000,
      80.19,
      80.86,
      79.73,
      80.83,
      87642800
  ],
  [
      1591191000000,
      81.17,
      81.55,
      80.57,
      81.28,
      104491200
  ],
  [
      1591277400000,
      81.1,
      81.4,
      80.19,
      80.58,
      87560400
  ],
  [
      1591363800000,
      80.84,
      82.94,
      80.81,
      82.88,
      137250400
  ],
  [
      1591623000000,
      82.56,
      83.4,
      81.83,
      83.36,
      95654400
  ],
  [
      1591709400000,
      83.04,
      86.4,
      83,
      86,
      147712400
  ],
  [
      1591795800000,
      86.97,
      88.69,
      86.52,
      88.21,
      166651600
  ],
  [
      1591882200000,
      87.33,
      87.76,
      83.87,
      83.97,
      201662400
  ],
  [
      1591968600000,
      86.18,
      86.95,
      83.56,
      84.7,
      200146000
  ],
  [
      1592227800000,
      83.31,
      86.42,
      83.14,
      85.75,
      138808800
  ],
  [
      1592314200000,
      87.86,
      88.3,
      86.18,
      88.02,
      165428800
  ],
  [
      1592400600000,
      88.79,
      88.85,
      87.77,
      87.9,
      114406400
  ],
  [
      1592487000000,
      87.85,
      88.36,
      87.31,
      87.93,
      96820400
  ],
  [
      1592573400000,
      88.66,
      89.14,
      86.29,
      87.43,
      264476000
  ],
  [
      1592832600000,
      87.83,
      89.86,
      87.79,
      89.72,
      135445200
  ],
  [
      1592919000000,
      91,
      93.1,
      90.57,
      91.63,
      212155600
  ],
  [
      1593005400000,
      91.25,
      92.2,
      89.63,
      90.01,
      192623200
  ],
  [
      1593091800000,
      90.18,
      91.25,
      89.39,
      91.21,
      137522400
  ],
  [
      1593178200000,
      91.1,
      91.33,
      88.25,
      88.41,
      205256800
  ],
  [
      1593437400000,
      88.31,
      90.54,
      87.82,
      90.44,
      130646000
  ],
  [
      1593523800000,
      90.02,
      91.5,
      90,
      91.2,
      140223200
  ],
  [
      1593610200000,
      91.28,
      91.84,
      90.98,
      91.03,
      110737200
  ],
  [
      1593696600000,
      91.96,
      92.62,
      90.91,
      91.03,
      114041600
  ],
  [
      1594042200000,
      92.5,
      93.94,
      92.47,
      93.46,
      118655600
  ],
  [
      1594128600000,
      93.85,
      94.65,
      93.06,
      93.17,
      112424400
  ],
  [
      1594215000000,
      94.18,
      95.38,
      94.09,
      95.34,
      117092000
  ],
  [
      1594301400000,
      96.26,
      96.32,
      94.67,
      95.75,
      125642800
  ],
  [
      1594387800000,
      95.33,
      95.98,
      94.71,
      95.92,
      90257200
  ],
  [
      1594647000000,
      97.26,
      99.96,
      95.26,
      95.48,
      191649200
  ],
  [
      1594733400000,
      94.84,
      97.25,
      93.88,
      97.06,
      170989200
  ],
  [
      1594819800000,
      98.99,
      99.25,
      96.49,
      97.72,
      153198000
  ],
  [
      1594906200000,
      96.56,
      97.4,
      95.9,
      96.52,
      110577600
  ],
  [
      1594992600000,
      96.99,
      97.15,
      95.84,
      96.33,
      92186800
  ],
  [
      1595251800000,
      96.42,
      98.5,
      96.06,
      98.36,
      90318000
  ],
  [
      1595338200000,
      99.17,
      99.25,
      96.74,
      97,
      103433200
  ],
  [
      1595424600000,
      96.69,
      97.97,
      96.6,
      97.27,
      89001600
  ],
  [
      1595511000000,
      97,
      97.08,
      92.01,
      92.85,
      197004400
  ],
  [
      1595597400000,
      90.99,
      92.97,
      89.14,
      92.61,
      185438800
  ],
  [
      1595856600000,
      93.71,
      94.9,
      93.48,
      94.81,
      121214000
  ],
  [
      1595943000000,
      94.37,
      94.55,
      93.25,
      93.25,
      103625600
  ],
  [
      1596029400000,
      93.75,
      95.23,
      93.71,
      95.04,
      90329200
  ],
  [
      1596115800000,
      94.19,
      96.3,
      93.77,
      96.19,
      158130000
  ],
  [
      1596202200000,
      102.89,
      106.42,
      100.82,
      106.26,
      374336800
  ],
  [
      1596461400000,
      108.2,
      111.64,
      107.89,
      108.94,
      308151200
  ],
  [
      1596547800000,
      109.13,
      110.79,
      108.39,
      109.67,
      173071600
  ],
  [
      1596634200000,
      109.38,
      110.39,
      108.9,
      110.06,
      121776800
  ],
  [
      1596720600000,
      110.4,
      114.41,
      109.8,
      113.9,
      202428800
  ],
  [
      1596807000000,
      113.21,
      113.68,
      110.29,
      111.11,
      198045600
  ],
  [
      1597066200000,
      112.6,
      113.78,
      110,
      112.73,
      212403600
  ],
  [
      1597152600000,
      111.97,
      112.48,
      109.11,
      109.38,
      187902400
  ],
  [
      1597239000000,
      110.5,
      113.28,
      110.3,
      113.01,
      165598000
  ],
  [
      1597325400000,
      114.43,
      116.04,
      113.93,
      115.01,
      210082000
  ],
  [
      1597411800000,
      114.83,
      115,
      113.04,
      114.91,
      165565200
  ],
  [
      1597671000000,
      116.06,
      116.09,
      113.96,
      114.61,
      119561600
  ],
  [
      1597757400000,
      114.35,
      116,
      114.01,
      115.56,
      105633600
  ],
  [
      1597843800000,
      115.98,
      117.16,
      115.61,
      115.71,
      145538000
  ],
  [
      1597930200000,
      115.75,
      118.39,
      115.73,
      118.28,
      126907200
  ],
  [
      1598016600000,
      119.26,
      124.87,
      119.25,
      124.37,
      338054800
  ],
  [
      1598275800000,
      128.7,
      128.79,
      123.94,
      125.86,
      345937600
  ],
  [
      1598362200000,
      124.7,
      125.18,
      123.05,
      124.82,
      211495600
  ],
  [
      1598448600000,
      126.18,
      126.99,
      125.08,
      126.52,
      163022400
  ],
  [
      1598535000000,
      127.14,
      127.49,
      123.83,
      125.01,
      155552400
  ],
  [
      1598621400000,
      126.01,
      126.44,
      124.58,
      124.81,
      187630000
  ],
  [
      1598880600000,
      127.58,
      131,
      126,
      129.04,
      225702700
  ],
  [
      1598967000000,
      132.76,
      134.8,
      130.53,
      134.18,
      151948100
  ],
  [
      1599053400000,
      137.59,
      137.98,
      127,
      131.4,
      200119000
  ],
  [
      1599139800000,
      126.91,
      128.84,
      120.5,
      120.88,
      257599600
  ],
  [
      1599226200000,
      120.07,
      123.7,
      110.89,
      120.96,
      332607200
  ],
  [
      1599571800000,
      113.95,
      118.99,
      112.68,
      112.82,
      231366600
  ],
  [
      1599658200000,
      117.26,
      119.14,
      115.26,
      117.32,
      176940500
  ],
  [
      1599744600000,
      120.36,
      120.5,
      112.5,
      113.49,
      182274400
  ],
  [
      1599831000000,
      114.57,
      115.23,
      110,
      112,
      180860300
  ],
  [
      1600090200000,
      114.72,
      115.93,
      112.8,
      115.36,
      140150100
  ],
  [
      1600176600000,
      118.33,
      118.83,
      113.61,
      115.54,
      184642000
  ],
  [
      1600263000000,
      115.23,
      116,
      112.04,
      112.13,
      154679000
  ],
  [
      1600349400000,
      109.72,
      112.2,
      108.71,
      110.34,
      178011000
  ],
  [
      1600435800000,
      110.4,
      110.88,
      106.09,
      106.84,
      287104900
  ],
  [
      1600695000000,
      104.54,
      110.19,
      103.1,
      110.08,
      195713800
  ],
  [
      1600781400000,
      112.68,
      112.86,
      109.16,
      111.81,
      183055400
  ],
  [
      1600867800000,
      111.62,
      112.11,
      106.77,
      107.12,
      150718700
  ],
  [
      1600954200000,
      105.17,
      110.25,
      105,
      108.22,
      167743300
  ],
  [
      1601040600000,
      108.43,
      112.44,
      107.67,
      112.28,
      149981400
  ],
  [
      1601299800000,
      115.01,
      115.32,
      112.78,
      114.96,
      137672400
  ],
  [
      1601386200000,
      114.55,
      115.31,
      113.57,
      114.09,
      99382200
  ],
  [
      1601472600000,
      113.79,
      117.26,
      113.62,
      115.81,
      142675200
  ],
  [
      1601559000000,
      117.64,
      117.72,
      115.83,
      116.79,
      116120400
  ],
  [
      1601645400000,
      112.89,
      115.37,
      112.22,
      113.02,
      144712000
  ],
  [
      1601904600000,
      113.91,
      116.65,
      113.55,
      116.5,
      106243800
  ],
  [
      1601991000000,
      115.7,
      116.12,
      112.25,
      113.16,
      161498200
  ],
  [
      1602077400000,
      114.62,
      115.55,
      114.13,
      115.08,
      96849000
  ],
  [
      1602163800000,
      116.25,
      116.4,
      114.59,
      114.97,
      83477200
  ],
  [
      1602250200000,
      115.28,
      117,
      114.92,
      116.97,
      100506900
  ],
  [
      1602509400000,
      120.06,
      125.18,
      119.28,
      124.4,
      240226800
  ],
  [
      1602595800000,
      125.27,
      125.39,
      119.65,
      121.1,
      262330500
  ],
  [
      1602682200000,
      121,
      123.03,
      119.62,
      121.19,
      150712000
  ],
  [
      1602768600000,
      118.72,
      121.2,
      118.15,
      120.71,
      112559200
  ],
  [
      1602855000000,
      121.28,
      121.55,
      118.81,
      119.02,
      115393800
  ],
  [
      1603114200000,
      119.96,
      120.42,
      115.66,
      115.98,
      120639300
  ],
  [
      1603200600000,
      116.2,
      118.98,
      115.63,
      117.51,
      124423700
  ],
  [
      1603287000000,
      116.67,
      118.71,
      116.45,
      116.87,
      89946000
  ],
  [
      1603373400000,
      117.45,
      118.04,
      114.59,
      115.75,
      101988000
  ],
  [
      1603459800000,
      116.39,
      116.55,
      114.28,
      115.04,
      82572600
  ],
  [
      1603719000000,
      114.01,
      116.55,
      112.88,
      115.05,
      111850700
  ],
  [
      1603805400000,
      115.49,
      117.28,
      114.54,
      116.6,
      92276800
  ],
  [
      1603891800000,
      115.05,
      115.43,
      111.1,
      111.2,
      143937800
  ],
  [
      1603978200000,
      112.37,
      116.93,
      112.2,
      115.32,
      146129200
  ],
  [
      1604064600000,
      111.06,
      111.99,
      107.72,
      108.86,
      190272600
  ],
  [
      1604327400000,
      109.11,
      110.68,
      107.32,
      108.77,
      122866900
  ],
  [
      1604413800000,
      109.66,
      111.49,
      108.73,
      110.44,
      107624400
  ],
  [
      1604500200000,
      114.14,
      115.59,
      112.35,
      114.95,
      138235500
  ],
  [
      1604586600000,
      117.95,
      119.62,
      116.87,
      119.03,
      126387100
  ],
  [
      1604673000000,
      118.32,
      119.2,
      116.13,
      118.69,
      114457900
  ],
  [
      1604932200000,
      120.5,
      121.99,
      116.05,
      116.32,
      154515300
  ],
  [
      1605018600000,
      115.55,
      117.59,
      114.13,
      115.97,
      138023400
  ],
  [
      1605105000000,
      117.19,
      119.63,
      116.44,
      119.49,
      112295000
  ],
  [
      1605191400000,
      119.62,
      120.53,
      118.57,
      119.21,
      103162300
  ],
  [
      1605277800000,
      119.44,
      119.67,
      117.87,
      119.26,
      81581900
  ],
  [
      1605537000000,
      118.92,
      120.99,
      118.15,
      120.3,
      91183000
  ],
  [
      1605623400000,
      119.55,
      120.67,
      118.96,
      119.39,
      74271000
  ],
  [
      1605709800000,
      118.61,
      119.82,
      118,
      118.03,
      76322100
  ],
  [
      1605796200000,
      117.59,
      119.06,
      116.81,
      118.64,
      74113000
  ],
  [
      1605882600000,
      118.64,
      118.77,
      117.29,
      117.34,
      73604300
  ],
  [
      1606141800000,
      117.18,
      117.62,
      113.75,
      113.85,
      127959300
  ],
  [
      1606228200000,
      113.91,
      115.85,
      112.59,
      115.17,
      113874200
  ],
  [
      1606314600000,
      115.55,
      116.75,
      115.17,
      116.03,
      76499200
  ],
  [
      1606487400000,
      116.57,
      117.49,
      116.22,
      116.59,
      46691300
  ],
  [
      1606746600000,
      116.97,
      120.97,
      116.81,
      119.05,
      169410200
  ],
  [
      1606833000000,
      121.01,
      123.47,
      120.01,
      122.72,
      127728200
  ],
  [
      1606919400000,
      122.02,
      123.37,
      120.89,
      123.08,
      89004200
  ],
  [
      1607005800000,
      123.52,
      123.78,
      122.21,
      122.94,
      78967600
  ],
  [
      1607092200000,
      122.6,
      122.86,
      121.52,
      122.25,
      78260400
  ],
  [
      1607351400000,
      122.31,
      124.57,
      122.25,
      123.75,
      86712000
  ],
  [
      1607437800000,
      124.37,
      124.98,
      123.09,
      124.38,
      82225500
  ],
  [
      1607524200000,
      124.53,
      125.95,
      121,
      121.78,
      115089200
  ],
  [
      1607610600000,
      120.5,
      123.87,
      120.15,
      123.24,
      81312200
  ],
  [
      1607697000000,
      122.43,
      122.76,
      120.55,
      122.41,
      86939800
  ],
  [
      1607956200000,
      122.6,
      123.35,
      121.54,
      121.78,
      79184500
  ],
  [
      1608042600000,
      124.34,
      127.9,
      124.13,
      127.88,
      157243700
  ],
  [
      1608129000000,
      127.41,
      128.37,
      126.56,
      127.81,
      98208600
  ],
  [
      1608215400000,
      128.9,
      129.58,
      128.04,
      128.7,
      94359800
  ],
  [
      1608301800000,
      128.96,
      129.1,
      126.12,
      126.66,
      192541500
  ],
  [
      1608561000000,
      125.02,
      128.31,
      123.45,
      128.23,
      121251600
  ],
  [
      1608647400000,
      131.61,
      134.41,
      129.65,
      131.88,
      168904800
  ],
  [
      1608733800000,
      132.16,
      132.43,
      130.78,
      130.96,
      88223700
  ],
  [
      1608820200000,
      131.32,
      133.46,
      131.1,
      131.97,
      54930100
  ],
  [
      1609165800000,
      133.99,
      137.34,
      133.51,
      136.69,
      124486200
  ],
  [
      1609252200000,
      138.05,
      138.79,
      134.34,
      134.87,
      121047300
  ],
  [
      1609338600000,
      135.58,
      135.99,
      133.4,
      133.72,
      96452100
  ],
  [
      1609425000000,
      134.08,
      134.74,
      131.72,
      132.69,
      99116600
  ],
  [
      1609770600000,
      133.52,
      133.61,
      126.76,
      129.41,
      143301900
  ],
  [
      1609857000000,
      128.89,
      131.74,
      128.43,
      131.01,
      97664900
  ],
  [
      1609943400000,
      127.72,
      131.05,
      126.38,
      126.6,
      155088000
  ],
  [
      1610029800000,
      128.36,
      131.63,
      127.86,
      130.92,
      109578200
  ],
  [
      1610116200000,
      132.43,
      132.63,
      130.23,
      132.05,
      105158200
  ],
  [
      1610375400000,
      129.19,
      130.17,
      128.5,
      128.98,
      100384500
  ],
  [
      1610461800000,
      128.5,
      129.69,
      126.86,
      128.8,
      91951100
  ],
  [
      1610548200000,
      128.76,
      131.45,
      128.49,
      130.89,
      88636800
  ],
  [
      1610634600000,
      130.8,
      131,
      128.76,
      128.91,
      90221800
  ],
  [
      1610721000000,
      128.78,
      130.22,
      127,
      127.14,
      111598500
  ],
  [
      1611066600000,
      127.78,
      128.71,
      126.94,
      127.83,
      90757300
  ],
  [
      1611153000000,
      128.66,
      132.49,
      128.55,
      132.03,
      104319500
  ],
  [
      1611239400000,
      133.8,
      139.67,
      133.59,
      136.87,
      120150900
  ],
  [
      1611325800000,
      136.28,
      139.85,
      135.02,
      139.07,
      114459400
  ],
  [
      1611585000000,
      143.07,
      145.09,
      136.54,
      142.92,
      157611700
  ],
  [
      1611671400000,
      143.6,
      144.3,
      141.37,
      143.16,
      98390600
  ],
  [
      1611757800000,
      143.43,
      144.3,
      140.41,
      142.06,
      140843800
  ],
  [
      1611844200000,
      139.52,
      141.99,
      136.7,
      137.09,
      142621100
  ],
  [
      1611930600000,
      135.83,
      136.74,
      130.21,
      131.96,
      177523800
  ],
  [
      1612189800000,
      133.75,
      135.38,
      130.93,
      134.14,
      106239800
  ],
  [
      1612276200000,
      135.73,
      136.31,
      134.61,
      134.99,
      83305400
  ],
  [
      1612362600000,
      135.76,
      135.77,
      133.61,
      133.94,
      89880900
  ],
  [
      1612449000000,
      136.3,
      137.4,
      134.59,
      137.39,
      84183100
  ],
  [
      1612535400000,
      137.35,
      137.42,
      135.86,
      136.76,
      75693800
  ],
  [
      1612794600000,
      136.03,
      136.96,
      134.92,
      136.91,
      71297200
  ],
  [
      1612881000000,
      136.62,
      137.88,
      135.85,
      136.01,
      76774200
  ],
  [
      1612967400000,
      136.48,
      136.99,
      134.4,
      135.39,
      73046600
  ],
  [
      1613053800000,
      135.9,
      136.39,
      133.77,
      135.13,
      64280000
  ],
  [
      1613140200000,
      134.35,
      135.53,
      133.69,
      135.37,
      60145100
  ],
  [
      1613485800000,
      135.49,
      136.01,
      132.79,
      133.19,
      80576300
  ],
  [
      1613572200000,
      131.25,
      132.22,
      129.47,
      130.84,
      97918500
  ],
  [
      1613658600000,
      129.2,
      130,
      127.41,
      129.71,
      96856700
  ],
  [
      1613745000000,
      130.24,
      130.71,
      128.8,
      129.87,
      87668800
  ],
  [
      1614004200000,
      128.01,
      129.72,
      125.6,
      126,
      103916400
  ],
  [
      1614090600000,
      123.76,
      126.71,
      118.39,
      125.86,
      158273000
  ],
  [
      1614177000000,
      124.94,
      125.56,
      122.23,
      125.35,
      111039900
  ],
  [
      1614263400000,
      124.68,
      126.46,
      120.54,
      120.99,
      148199500
  ],
  [
      1614349800000,
      122.59,
      124.85,
      121.2,
      121.26,
      164560400
  ],
  [
      1614609000000,
      123.75,
      127.93,
      122.79,
      127.79,
      116307900
  ],
  [
      1614695400000,
      128.41,
      128.72,
      125.01,
      125.12,
      102260900
  ],
  [
      1614781800000,
      124.81,
      125.71,
      121.84,
      122.06,
      112966300
  ],
  [
      1614868200000,
      121.75,
      123.6,
      118.62,
      120.13,
      178155000
  ],
  [
      1614954600000,
      120.98,
      121.94,
      117.57,
      121.42,
      153766600
  ],
  [
      1615213800000,
      120.93,
      121,
      116.21,
      116.36,
      154376600
  ],
  [
      1615300200000,
      119.03,
      122.06,
      118.79,
      121.09,
      129525800
  ],
  [
      1615386600000,
      121.69,
      122.17,
      119.45,
      119.98,
      111943300
  ],
  [
      1615473000000,
      122.54,
      123.21,
      121.26,
      121.96,
      103026500
  ],
  [
      1615559400000,
      120.4,
      121.17,
      119.16,
      121.03,
      88105100
  ],
  [
      1615815000000,
      121.41,
      124,
      120.42,
      123.99,
      92403800
  ],
  [
      1615901400000,
      125.7,
      127.22,
      124.72,
      125.57,
      115227900
  ],
  [
      1615987800000,
      124.05,
      125.86,
      122.34,
      124.76,
      111932600
  ],
  [
      1616074200000,
      122.88,
      123.18,
      120.32,
      120.53,
      121229700
  ],
  [
      1616160600000,
      119.9,
      121.43,
      119.68,
      119.99,
      185549500
  ],
  [
      1616419800000,
      120.33,
      123.87,
      120.26,
      123.39,
      111912300
  ],
  [
      1616506200000,
      123.33,
      124.24,
      122.14,
      122.54,
      95467100
  ],
  [
      1616592600000,
      122.82,
      122.9,
      120.07,
      120.09,
      88530500
  ],
  [
      1616679000000,
      119.54,
      121.66,
      119,
      120.59,
      98844700
  ],
  [
      1616765400000,
      120.35,
      121.48,
      118.92,
      121.21,
      94071200
  ],
  [
      1617024600000,
      121.65,
      122.58,
      120.73,
      121.39,
      80819200
  ],
  [
      1617111000000,
      120.11,
      120.4,
      118.86,
      119.9,
      85671900
  ],
  [
      1617197400000,
      121.65,
      123.52,
      121.15,
      122.15,
      118323800
  ],
  [
      1617283800000,
      123.66,
      124.18,
      122.49,
      123,
      75089100
  ],
  [
      1617629400000,
      123.87,
      126.16,
      123.07,
      125.9,
      88651200
  ],
  [
      1617715800000,
      126.5,
      127.13,
      125.65,
      126.21,
      80171300
  ],
  [
      1617802200000,
      125.83,
      127.92,
      125.14,
      127.9,
      83466700
  ],
  [
      1617888600000,
      128.95,
      130.39,
      128.52,
      130.36,
      88844600
  ],
  [
      1617975000000,
      129.8,
      133.04,
      129.47,
      133,
      106686700
  ],
  [
      1618234200000,
      132.52,
      132.85,
      130.63,
      131.24,
      91420000
  ],
  [
      1618320600000,
      132.44,
      134.66,
      131.93,
      134.43,
      91266500
  ],
  [
      1618407000000,
      134.94,
      135,
      131.66,
      132.03,
      87222800
  ],
  [
      1618493400000,
      133.82,
      135,
      133.64,
      134.5,
      89347100
  ],
  [
      1618579800000,
      134.3,
      134.67,
      133.28,
      134.16,
      84922400
  ],
  [
      1618839000000,
      133.51,
      135.47,
      133.34,
      134.84,
      94264200
  ],
  [
      1618925400000,
      135.02,
      135.53,
      131.81,
      133.11,
      94812300
  ],
  [
      1619011800000,
      132.36,
      133.75,
      131.3,
      133.5,
      68847100
  ],
  [
      1619098200000,
      133.04,
      134.15,
      131.41,
      131.94,
      84566500
  ],
  [
      1619184600000,
      132.16,
      135.12,
      132.16,
      134.32,
      78657500
  ],
  [
      1619443800000,
      134.83,
      135.06,
      133.56,
      134.72,
      66905100
  ],
  [
      1619530200000,
      135.01,
      135.41,
      134.11,
      134.39,
      66015800
  ],
  [
      1619616600000,
      134.31,
      135.02,
      133.08,
      133.58,
      107760100
  ],
  [
      1619703000000,
      136.47,
      137.07,
      132.45,
      133.48,
      151101000
  ],
  [
      1619789400000,
      131.78,
      133.56,
      131.07,
      131.46,
      109839500
  ],
  [
      1620048600000,
      132.04,
      134.07,
      131.83,
      132.54,
      75135100
  ],
  [
      1620135000000,
      131.19,
      131.49,
      126.7,
      127.85,
      137564700
  ],
  [
      1620221400000,
      129.2,
      130.45,
      127.97,
      128.1,
      84000900
  ],
  [
      1620307800000,
      127.89,
      129.75,
      127.13,
      129.74,
      78128300
  ],
  [
      1620394200000,
      130.85,
      131.26,
      129.48,
      130.21,
      78973300
  ],
  [
      1620653400000,
      129.41,
      129.54,
      126.81,
      126.85,
      88071200
  ],
  [
      1620739800000,
      123.5,
      126.27,
      122.77,
      125.91,
      126142800
  ],
  [
      1620826200000,
      123.4,
      124.64,
      122.25,
      122.77,
      112172300
  ],
  [
      1620912600000,
      124.58,
      126.15,
      124.26,
      124.97,
      105861300
  ],
  [
      1620999000000,
      126.25,
      127.89,
      125.85,
      127.45,
      81918000
  ],
  [
      1621258200000,
      126.82,
      126.93,
      125.17,
      126.27,
      74244600
  ],
  [
      1621344600000,
      126.56,
      126.99,
      124.78,
      124.85,
      63342900
  ],
  [
      1621431000000,
      123.16,
      124.92,
      122.86,
      124.69,
      92612000
  ],
  [
      1621517400000,
      125.23,
      127.72,
      125.1,
      127.31,
      76857100
  ],
  [
      1621603800000,
      127.82,
      128,
      125.21,
      125.43,
      79295400
  ],
  [
      1621863000000,
      126.01,
      127.94,
      125.94,
      127.1,
      63092900
  ],
  [
      1621949400000,
      127.82,
      128.32,
      126.32,
      126.9,
      72009500
  ],
  [
      1622035800000,
      126.96,
      127.39,
      126.42,
      126.85,
      56575900
  ],
  [
      1622122200000,
      126.44,
      127.64,
      125.08,
      125.28,
      94625600
  ],
  [
      1622208600000,
      125.57,
      125.8,
      124.55,
      124.61,
      71311100
  ],
  [
      1622554200000,
      125.08,
      125.35,
      123.94,
      124.28,
      67637100
  ],
  [
      1622640600000,
      124.28,
      125.24,
      124.05,
      125.06,
      59278900
  ],
  [
      1622727000000,
      124.68,
      124.85,
      123.13,
      123.54,
      76229200
  ],
  [
      1622813400000,
      124.07,
      126.16,
      123.85,
      125.89,
      75169300
  ],
  [
      1623072600000,
      126.17,
      126.32,
      124.83,
      125.9,
      71057600
  ],
  [
      1623159000000,
      126.6,
      128.46,
      126.21,
      126.74,
      74403800
  ],
  [
      1623245400000,
      127.21,
      127.75,
      126.52,
      127.13,
      56877900
  ],
  [
      1623331800000,
      127.02,
      128.19,
      125.94,
      126.11,
      71186400
  ],
  [
      1623418200000,
      126.53,
      127.44,
      126.1,
      127.35,
      53522400
  ],
  [
      1623677400000,
      127.82,
      130.54,
      127.07,
      130.48,
      96906500
  ],
  [
      1623763800000,
      129.94,
      130.6,
      129.39,
      129.64,
      62746300
  ],
  [
      1623850200000,
      130.37,
      130.89,
      128.46,
      130.15,
      91815000
  ],
  [
      1623936600000,
      129.8,
      132.55,
      129.65,
      131.79,
      96721700
  ],
  [
      1624023000000,
      130.71,
      131.51,
      130.24,
      130.46,
      108953300
  ],
  [
      1624282200000,
      130.3,
      132.41,
      129.21,
      132.3,
      79663300
  ],
  [
      1624368600000,
      132.13,
      134.08,
      131.62,
      133.98,
      74783600
  ],
  [
      1624455000000,
      133.77,
      134.32,
      133.23,
      133.7,
      60214200
  ],
  [
      1624541400000,
      134.45,
      134.64,
      132.93,
      133.41,
      68711000
  ],
  [
      1624627800000,
      133.46,
      133.89,
      132.81,
      133.11,
      70783700
  ],
  [
      1624887000000,
      133.41,
      135.25,
      133.35,
      134.78,
      62111300
  ],
  [
      1624973400000,
      134.8,
      136.49,
      134.35,
      136.33,
      64556100
  ],
  [
      1625059800000,
      136.17,
      137.41,
      135.87,
      136.96,
      63261400
  ],
  [
      1625146200000,
      136.6,
      137.33,
      135.76,
      137.27,
      52485800
  ],
  [
      1625232600000,
      137.9,
      140,
      137.75,
      139.96,
      78852600
  ],
  [
      1625578200000,
      140.07,
      143.15,
      140.07,
      142.02,
      108181800
  ],
  [
      1625664600000,
      143.54,
      144.89,
      142.66,
      144.57,
      104911600
  ],
  [
      1625751000000,
      141.58,
      144.06,
      140.67,
      143.24,
      105575500
  ],
  [
      1625837400000,
      142.75,
      145.65,
      142.65,
      145.11,
      99890800
  ],
  [
      1626096600000,
      146.21,
      146.32,
      144,
      144.5,
      76299700
  ],
  [
      1626183000000,
      144.03,
      147.46,
      143.63,
      145.64,
      100827100
  ],
  [
      1626269400000,
      148.1,
      149.57,
      147.68,
      149.15,
      127050800
  ],
  [
      1626355800000,
      149.24,
      150,
      147.09,
      148.48,
      106820300
  ],
  [
      1626442200000,
      148.46,
      149.76,
      145.88,
      146.39,
      93251400
  ],
  [
      1626701400000,
      143.75,
      144.07,
      141.67,
      142.45,
      121434600
  ],
  [
      1626787800000,
      143.46,
      147.1,
      142.96,
      146.15,
      96350000
  ],
  [
      1626874200000,
      145.53,
      146.13,
      144.63,
      145.4,
      74993500
  ],
  [
      1626960600000,
      145.94,
      148.2,
      145.81,
      146.8,
      77338200
  ],
  [
      1627047000000,
      147.55,
      148.72,
      146.92,
      148.56,
      71447400
  ],
  [
      1627306200000,
      148.27,
      149.83,
      147.7,
      148.99,
      72434100
  ],
  [
      1627392600000,
      149.12,
      149.21,
      145.55,
      146.77,
      104818600
  ],
  [
      1627479000000,
      144.81,
      146.97,
      142.54,
      144.98,
      118931200
  ],
  [
      1627565400000,
      144.69,
      146.55,
      144.58,
      145.64,
      56699500
  ],
  [
      1627651800000,
      144.38,
      146.33,
      144.11,
      145.86,
      70382000
  ],
  [
      1627911000000,
      146.36,
      146.95,
      145.25,
      145.52,
      62880000
  ],
  [
      1627997400000,
      145.81,
      148.04,
      145.18,
      147.36,
      64786600
  ],
  [
      1628083800000,
      147.27,
      147.79,
      146.28,
      146.95,
      56368300
  ],
  [
      1628170200000,
      146.98,
      147.84,
      146.17,
      147.06,
      46397700
  ],
  [
      1628256600000,
      146.35,
      147.11,
      145.63,
      146.14,
      54067400
  ],
  [
      1628515800000,
      146.2,
      146.7,
      145.52,
      146.09,
      48908700
  ],
  [
      1628602200000,
      146.44,
      147.71,
      145.3,
      145.6,
      69023100
  ],
  [
      1628688600000,
      146.05,
      146.72,
      145.53,
      145.86,
      48493500
  ],
  [
      1628775000000,
      146.19,
      149.05,
      145.84,
      148.89,
      72282600
  ],
  [
      1628861400000,
      148.97,
      149.44,
      148.27,
      149.1,
      59318800
  ],
  [
      1629120600000,
      148.54,
      151.19,
      146.47,
      151.12,
      103296000
  ],
  [
      1629207000000,
      150.23,
      151.68,
      149.09,
      150.19,
      92229700
  ],
  [
      1629293400000,
      149.8,
      150.72,
      146.15,
      146.36,
      86326000
  ],
  [
      1629379800000,
      145.03,
      148,
      144.5,
      146.7,
      86960300
  ],
  [
      1629466200000,
      147.44,
      148.5,
      146.78,
      148.19,
      59947400
  ],
  [
      1629725400000,
      148.31,
      150.19,
      147.89,
      149.71,
      60131800
  ],
  [
      1629811800000,
      149.45,
      150.86,
      149.15,
      149.62,
      48606400
  ],
  [
      1629898200000,
      149.81,
      150.32,
      147.8,
      148.36,
      58991300
  ],
  [
      1629984600000,
      148.35,
      149.12,
      147.51,
      147.54,
      48597200
  ],
  [
      1630071000000,
      147.48,
      148.75,
      146.83,
      148.6,
      55721500
  ],
  [
      1630330200000,
      149,
      153.49,
      148.61,
      153.12,
      90956700
  ],
  [
      1630416600000,
      152.66,
      152.8,
      151.29,
      151.83,
      86453100
  ],
  [
      1630503000000,
      152.83,
      154.98,
      152.34,
      152.51,
      80313700
  ],
  [
      1630589400000,
      153.87,
      154.72,
      152.4,
      153.65,
      71115500
  ],
  [
      1630675800000,
      153.76,
      154.63,
      153.09,
      154.3,
      57808700
  ],
  [
      1631021400000,
      154.97,
      157.26,
      154.39,
      156.69,
      82278300
  ],
  [
      1631107800000,
      156.98,
      157.04,
      153.98,
      155.11,
      74420200
  ],
  [
      1631194200000,
      155.49,
      156.11,
      153.95,
      154.07,
      57305700
  ],
  [
      1631280600000,
      155,
      155.48,
      148.7,
      148.97,
      140646400
  ],
  [
      1631539800000,
      150.63,
      151.42,
      148.75,
      149.55,
      102404300
  ],
  [
      1631626200000,
      150.35,
      151.07,
      146.91,
      148.12,
      109296300
  ],
  [
      1631712600000,
      148.56,
      149.44,
      146.37,
      149.03,
      83281300
  ],
  [
      1631799000000,
      148.44,
      148.97,
      147.22,
      148.79,
      68034100
  ],
  [
      1631885400000,
      148.82,
      148.82,
      145.76,
      146.06,
      129868800
  ],
  [
      1632144600000,
      143.8,
      144.84,
      141.27,
      142.94,
      123478900
  ],
  [
      1632231000000,
      143.93,
      144.6,
      142.78,
      143.43,
      75834000
  ],
  [
      1632317400000,
      144.45,
      146.43,
      143.7,
      145.85,
      76404300
  ],
  [
      1632403800000,
      146.65,
      147.08,
      145.64,
      146.83,
      64838200
  ],
  [
      1632490200000,
      145.66,
      147.47,
      145.56,
      146.92,
      53477900
  ],
  [
      1632749400000,
      145.47,
      145.96,
      143.82,
      145.37,
      74150700
  ],
  [
      1632835800000,
      143.25,
      144.75,
      141.69,
      141.91,
      108972300
  ],
  [
      1632922200000,
      142.47,
      144.45,
      142.03,
      142.83,
      74602000
  ],
  [
      1633008600000,
      143.66,
      144.38,
      141.28,
      141.5,
      88934200
  ],
  [
      1633095000000,
      141.9,
      142.92,
      139.11,
      142.65,
      94639600
  ],
  [
      1633354200000,
      141.76,
      142.21,
      138.27,
      139.14,
      98322000
  ],
  [
      1633440600000,
      139.49,
      142.24,
      139.36,
      141.11,
      80861100
  ],
  [
      1633527000000,
      139.47,
      142.15,
      138.37,
      142,
      83221100
  ],
  [
      1633613400000,
      143.06,
      144.22,
      142.72,
      143.29,
      61732700
  ],
  [
      1633699800000,
      144.03,
      144.18,
      142.56,
      142.9,
      58718700
  ],
  [
      1633959000000,
      142.27,
      144.81,
      141.81,
      142.81,
      64452200
  ],
  [
      1634045400000,
      143.23,
      143.25,
      141.04,
      141.51,
      73035900
  ],
  [
      1634131800000,
      141.24,
      141.4,
      139.2,
      140.91,
      78762700
  ],
  [
      1634218200000,
      142.11,
      143.88,
      141.51,
      143.76,
      69907100
  ],
  [
      1634304600000,
      143.77,
      144.9,
      143.51,
      144.84,
      67885200
  ],
  [
      1634563800000,
      143.45,
      146.84,
      143.16,
      146.55,
      85589200
  ],
  [
      1634650200000,
      147.01,
      149.17,
      146.55,
      148.76,
      76378900
  ],
  [
      1634736600000,
      148.7,
      149.75,
      148.12,
      149.26,
      58418800
  ],
  [
      1634823000000,
      148.81,
      149.64,
      147.87,
      149.48,
      61421000
  ],
  [
      1634909400000,
      149.69,
      150.18,
      148.64,
      148.69,
      58883400
  ],
  [
      1635168600000,
      148.68,
      149.37,
      147.62,
      148.64,
      50720600
  ],
  [
      1635255000000,
      149.33,
      150.84,
      149.01,
      149.32,
      60893400
  ],
  [
      1635341400000,
      149.36,
      149.73,
      148.49,
      148.85,
      56094900
  ],
  [
      1635427800000,
      149.82,
      153.17,
      149.72,
      152.57,
      100077900
  ],
  [
      1635514200000,
      147.22,
      149.94,
      146.41,
      149.8,
      124850400
  ],
  [
      1635773400000,
      148.99,
      149.7,
      147.8,
      148.96,
      74588300
  ],
  [
      1635859800000,
      148.66,
      151.57,
      148.65,
      150.02,
      69122000
  ],
  [
      1635946200000,
      150.39,
      151.97,
      149.82,
      151.49,
      54511500
  ],
  [
      1636032600000,
      151.58,
      152.43,
      150.64,
      150.96,
      60394600
  ],
  [
      1636119000000,
      151.89,
      152.2,
      150.06,
      151.28,
      65414600
  ],
  [
      1636381800000,
      151.41,
      151.57,
      150.16,
      150.44,
      55020900
  ],
  [
      1636468200000,
      150.2,
      151.43,
      150.06,
      150.81,
      56787900
  ],
  [
      1636554600000,
      150.02,
      150.13,
      147.85,
      147.92,
      65187100
  ],
  [
      1636641000000,
      148.96,
      149.43,
      147.68,
      147.87,
      41000000
  ],
  [
      1636727400000,
      148.43,
      150.4,
      147.48,
      149.99,
      63632600
  ],
  [
      1636986600000,
      150.37,
      151.88,
      149.43,
      150,
      59222800
  ],
  [
      1637073000000,
      149.94,
      151.49,
      149.34,
      151,
      59256200
  ],
  [
      1637159400000,
      151,
      155,
      150.99,
      153.49,
      88807000
  ],
  [
      1637245800000,
      153.71,
      158.67,
      153.05,
      157.87,
      137827700
  ],
  [
      1637332200000,
      157.65,
      161.02,
      156.53,
      160.55,
      117305600
  ],
  [
      1637591400000,
      161.68,
      165.7,
      161,
      161.02,
      117467900
  ],
  [
      1637677800000,
      161.12,
      161.8,
      159.06,
      161.41,
      96041900
  ],
  [
      1637764200000,
      160.75,
      162.14,
      159.64,
      161.94,
      69463600
  ],
  [
      1637937000000,
      159.57,
      160.45,
      156.36,
      156.81,
      76959800
  ],
  [
      1638196200000,
      159.37,
      161.19,
      158.79,
      160.24,
      88748200
  ],
  [
      1638282600000,
      159.99,
      165.52,
      159.92,
      165.3,
      174048100
  ],
  [
      1638369000000,
      167.48,
      170.3,
      164.53,
      164.77,
      152052500
  ],
  [
      1638455400000,
      158.74,
      164.2,
      157.8,
      163.76,
      136474900
  ]
]





export const data1 = [
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
    {usdt:56564.2, btc:0.156, total:9.151684},
]