import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import mainImg from '../../images/main.png'
import style from './style.module.css'

export default function MainPhoto(){
    const matches800 = useMediaQuery('(max-width:800px)');
    const matches400 = useMediaQuery('(max-width:400px)');
    const variant = !matches800?'h3':!matches400?'h5':'h6'
    
    return(
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} position='relative' display='flex' alignItems='center'>
                <img src={mainImg} alt='main' width='100%'/>
                <div className={style.caption}>
                        <Typography variant={variant}>
                            Buy and  Sell Crypto in minutes
                        </Typography>
                        <Typography variant='caption'>
                            Join the world’s largest crypto exchange
                        </Typography>
                        <Link to='/sign-up' className={style.btn} style={{background:'linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);'}}>Register Now</Link>
                </div>
              </Grid>
            </Grid>
        </Box>
    )
}