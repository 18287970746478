import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import img1 from './images/20 1.png'
import img2 from './images/20 2.png'
import style from './style.module.css'
export default function RegAction(){
    return(
        <div className={style.reg_block}>
            <img src={img1} alt='reg_actions'/>
            <div className={style.actions}>
                <Typography variant={'h4'}>Start Trading Now</Typography>
                <div style={{display:'flex', alignItems:'center',position:'relative',zIndex:3}}>
                    <button><Link to='/sign-up'>Register Now</Link></button>
                    <button>Trade Now</button>
                </div>
            </div>
            <img src={img2} alt='reg_actions'/>
        </div>
    )
}