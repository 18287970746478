import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import logo from '../../images/logo.png'
import grid from './grid.png'
import menu from '../../images/menu.png'
import style from './style.module.css'
import { Link, NavLink, Outlet } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import down from './down.png'
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: '20px 110px',
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  boxShadow: '0px 4px 15px rgba(190, 190, 190, 0.25)'
}));
export default function LargeHeader() {

  
  return (
  <>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <div className={style.nav}>
                <img src={logo} alt='logo'/>
                <img src={grid} alt='menu'/>
                <MenuItem>Buy Crypto <span className={style.usd}>USD</span> <img src={down} alt='menu'/></MenuItem>
                <MenuItem>Markets</MenuItem>
                <MenuItem>Trade <img src={down} alt='menu'/></MenuItem>
                <MenuItem>Derivatives <img src={down} alt='menu'/></MenuItem>
                <MenuItem>Finance <img src={down} alt='menu'/></MenuItem>
            </div>
            <div className={style.nav}>
                <NavLink to='/sign-in' className={style.loginLink} end>Login</NavLink>
                <NavLink to='/sign-up' className={style.registerLink} end>Register</NavLink>
                
                  <MenuItem>Downloads</MenuItem>
                  <MenuItem>Eng <img src={down} alt='menu'/></MenuItem>
                  <MenuItem>USD <img src={down} alt='menu'/></MenuItem>
                 
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
    
    </>
  );
}


