import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import style from './style.module.css'
import logo from '../../images/logo.png'
import btc from './images/bitcoin.png'
import twt from './images/twitter.png'
import someF from './images/someface.png'
import tg from './images/telegram.png'
import inst from './images/insta.png'
import vk from './images/vk.png'
import you from './images/youtube.png'
import fb from './images/facebook.png'
export default function LargeFooter(){
    return(
        <Box sx={{paddingBottom:10, flexGrow: 1, background:'linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%)'  }}>
            <Grid container spacing={4} className={style.cont}>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                    <Grid item xs={12} marginBottom='90px'>
                        <div className={style.logo}>
                            <img src={logo} alt='logo'/>
                        </div>
                    </Grid>
                    <Typography style={{margin:17.5,color:'white'}}>Community</Typography>
                    <div>
                        <div className={style.icons}>
                            <div><img src={fb} alt='footer'/></div>
                            <div><img src={twt} alt='footer'/></div>
                            <div><img src={someF} alt='footer'/></div>
                            <div><img src={inst} alt='footer'/></div>
                        </div>
                        <div className={style.icons}>
                            <div><img src={tg} alt='footer'/></div>
                            <div><img src={btc} alt='footer'/></div>
                            <div><img src={you} alt='footer'/></div>
                            <div><img src={vk} alt='footer'/></div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography>About Us</Typography>
                    <List>
                        <ListItem>About Us</ListItem>
                        <ListItem>Bisiness Contacts</ListItem>
                        <ListItem>Community</ListItem>
                        <ListItem>Tazeco Blog</ListItem>
                        <ListItem>Terms</ListItem>
                        <ListItem>Privacy</ListItem>
                        <ListItem>News</ListItem>
                    </List>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography>Products</Typography>
                    <List>
                        <ListItem>Exchange</ListItem>
                        <ListItem>Academy</ListItem>
                        <ListItem>Charity</ListItem>
                        <ListItem>Card</ListItem>
                        <ListItem>Labs</ListItem>
                        <ListItem>Research</ListItem>
                        <ListItem>Trust Wallet</ListItem>
                        <ListItem>NFT</ListItem>
                        <ListItem>Gift Card</ListItem>
                    </List>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography>Service</Typography>
                    <List>
                        <ListItem>Downloads</ListItem>
                        <ListItem>Buy Cripto</ListItem>
                        <ListItem>Labs</ListItem>
                        <ListItem>Research</ListItem>
                        <ListItem>Trust Wallet</ListItem>
                        <ListItem>NFT</ListItem>
                        <ListItem>Gift Card</ListItem>
                        <ListItem>Trust Wallet</ListItem>
                        <ListItem>NFT</ListItem>
                        <ListItem>Gift Card</ListItem>
                    </List>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography>Support</Typography>
                    <List>
                        <ListItem>Labs</ListItem>
                        <ListItem>Research</ListItem>
                        <ListItem>Trust Wallet</ListItem>
                        <ListItem>NFT</ListItem>
                        <ListItem>Gift Card</ListItem>
                        <ListItem>Trust Wallet</ListItem>
                        <ListItem>NFT</ListItem>
                        <ListItem>Gift Card</ListItem>
                    </List>
                </Grid>
                <Grid item xs={1}>
                    <Typography>Learn</Typography>
                    <List>
                        <ListItem>Labs</ListItem>
                        <ListItem>Research</ListItem>
                        <ListItem>Trust Wallet</ListItem>
                        <ListItem>NFT</ListItem>
                        <ListItem>Gift Card</ListItem>
                        <ListItem>Trust Wallet</ListItem>
                        <ListItem>NFT</ListItem>
                        <ListItem>Gift Card</ListItem>
                        <ListItem>Labs</ListItem>
                        <ListItem>Research</ListItem>
                    </List>
                </Grid>
                 
            </Grid>
        </Box>
    )
}