import styled from "@emotion/styled"
import { useMediaQuery } from "@mui/material"
import { useState } from "react"
import notActive from '../notactive.png'
import SubToolBar from "./SubToolBar"
const Container = styled('div')(({resized}:any)=>({
    ...resized,
    marginBottom:160
}))

const Row = styled('div')(()=>({
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between'
}))

const Navigation = styled('div')(()=>({
    display:'flex',
    alignItems:'center'
}))

const Item = styled('li')(({background, color}:any)=>({
    display:'flex',
    alignItems:'center',
    listStyle:'none',
    margin:5,
    padding:'15px 20px',
    cursor:'pointer',
    borderRadius:10,
    background:background,
    color:color
}))

export default function LargeToolBar(){
    const [choosenData, setChoosenData] = useState<number | null>(null)
    const resized1600 = useMediaQuery('(max-width:1600px)')
    const resized1400 = useMediaQuery('(max-width:1400px)')
    const paddingSize = () =>{
        if(resized1600 && !resized1400){
            return {padding:'0 160px'}
        }else if(resized1600 && resized1400){
            return {padding:'0 60px'}
        }else{
            return {padding:'0 260px'}
        }
    } 
    const data = [
        [
            {id:1,img:notActive,title:'Favorites'},
            {id:2,title:'Spot Markets'},
            {id:3,title:'Futures Markets'},
            {id:4,title:'New listing'},
            {id:5,title:'All Cryptos'},
        ],
        [
            {id:6,title:'Markets Overview'},
            {id:7,title:'Top Movers'},
        ]
    ]

    return(
        <Container resized={paddingSize()}>
            <Row>
                {
                    data.map((item,i)=>{
                        return(
                            <Navigation key={i}>
                                {
                                    item.map(tools=>{
                                        return <Item onClick={()=>setChoosenData(tools.id)} key={tools.id + Math.random()} background={'#8AB399'} color={'white'}>{tools?.img&&<img src={notActive} alt="notActive" />} {tools.title}</Item>
                                    })
                                }
                            </Navigation>
                        )
                    })
                }
            </Row>
            <Row>
                {/* {choosenData&&<SubToolBar subId={choosenData}/>} */}
            </Row>
        </Container>
    )
} 