import { Typography, useMediaQuery } from "@mui/material";
import styled from "@mui/styled-engine";
import { useState, useEffect } from "react";
import crypto from "./crypto.png";
import right from "./right.png";

const Dashboard = styled("div")(({ padding }: any) => ({
  display: "flex",
  flexDirection: "column",
  height: 330,
  background: "#F8F8F8",
  padding: padding,
  justifyContent: "flex-end",
  marginBottom: 85,
}));

const Row = styled("div")(({ justify, marginTop }: any) => ({
  display: "flex",
  justifyContent: justify,
  alignItems: "center",
  marginTop: marginTop,
}));

const Item = styled("div")(({ selectedstyle }: any) => ({
  width: 351,
  height: 180,
  background: selectedstyle,
  borderRadius: 10,
  padding: "18px 34px",
  cursor: "pointer",
  userSelect: "none",
}));

const Input = styled("input")(() => ({
  width: 312,
  height: 50,
  background: "#FFFFFF",
  border: "1px solid #E0E0E0",
  boxSizing: "border-box",
  borderRadius: 3,
  paddingLeft: 10,
  outline: "none",
}));

export default function MarketDashboard() {
  const mobilePadding = useMediaQuery("(max-width:1600px)");
  const mobilecard = useMediaQuery("(max-width:1132px)");
  const isMobilecard = useMediaQuery("(max-width:830px)");
  const selectedstyle =
    "linear-gradient(104.61deg, rgba(138, 179, 153, 0.2) 8.29%, rgba(106, 161, 130, 0.2) 53.15%, rgba(106, 161, 130, 0.2) 98.01%)";
  const [data, setData] = useState<any[]>([
    {
      id: 1,
      top: "Top gainer (24h)",
      currency: "MBOX/BTC",
      percent: "+61.89",
      count: "0.00013142",
      income: 7,
    },
    {
      id: 2,
      top: "Top gainer (24h)",
      currency: "MBOX/BTC",
      percent: "+61.89",
      count: "0.00013142",
      income: 7,
    },
    {
      id: 3,
      top: "Top gainer (24h)",
      currency: "MBOX/BTC",
      percent: "+61.89",
      count: "0.00013142",
      income: 7,
    },
    {
      id: 4,
      top: "Top gainer (24h)",
      currency: "MBOX/BTC",
      percent: "+61.89",
      count: "0.00013142",
      income: 7,
    },
  ]);
  const [current, setCurrent] = useState<any>(null);
  const choose = (id: number) => {
    const currentBoard = data.find((item) => item.id === id);
    setCurrent(currentBoard);
    // setData(state.filter(item=>item.id))
  };

  return (
    <>
      {isMobilecard ? (
        <Row justify={"space-between"} style={{flexDirection:'column'}}>
          <Typography variant={"h4"}>Market</Typography>
          <Input placeholder={"Search..."} />
        </Row>
      ) : (
        <Dashboard padding={mobilePadding ? "0 20px" : "0 260px"}>
          <Row justify={"space-between"}>
            <Typography variant={"h4"}>Market</Typography>
            <Input placeholder={"Search..."} />
          </Row>
          <Row justify={"center"} marginTop={42}>
            {data.map((item, i) => {
              return (
                <Item
                  selectedstyle={item.id === current?.id && selectedstyle}
                  onClick={() => choose(+item.id)}
                >
                  <Typography variant={"caption"}>{item.top}</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: mobilecard ? "column" : "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 12,
                      marginTop: 20,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={crypto} alt="crypto" />
                      <Typography marginLeft={"12px"}>
                        {item.currency}
                      </Typography>
                    </div>
                    <Typography color={"green"}>{item.percent} %</Typography>
                  </div>
                  <Typography
                    variant={"subtitle1"}
                    style={{ fontWeight: "bold", marginBottom: 10 }}
                  >
                    {item.count}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant={"caption"}>${item.income}</Typography>
                    <img src={right} alt="right" />
                  </div>
                </Item>
              );
            })}
          </Row>
        </Dashboard>
      )}
    </>
  );
}
