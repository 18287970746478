import Footer from "../../components/Footer";
import CaptionCourse from "../../components/Main/CaptionCourse";
import MainPhoto from "../../components/Main/MainPhoto";
import RegAction from "../../components/Main/RegAction";
import Support from "../../components/Main/Support";
import Trend from "../../components/Main/Trend";

export default function Main(){
    return(
        <>
        <MainPhoto/>
        <CaptionCourse/>
        <Trend/>
        <RegAction/>
        <Support/>
        
        </>
    )
}