import style from './style.module.css'
import bit from './bit.png'
import play from './play.png'
export default function ContentHead(){
    return(
        <div className={style.content}>
            <div className={style.brand}>
                <h4>BTC/USDT</h4>
                <div>
                    <img src={bit}/>
                    <a href='#'>Bitcoin</a>
                </div>
            </div>
            <div className={style.details}>
                <div className={style.look}>
                    <p style={{color: '#2FA763'}}>56,673,27</p>
                    <p>$56,673,27</p>
                </div>
                <div className={style.look}>
                    <p>24h change</p>
                    <p style={{color: 'red'}}>-347.52-0.61%</p>
                </div>
                <div className={style.look}>
                    <p>24h High</p>
                    <p>56,673.27</p>
                </div>
                <div className={style.look}>
                    <p>24h Low</p>
                    <p>56,673.27</p>
                </div>
                <div className={style.look}>
                    <p>24h Volume(BTC)</p>
                    <p>56,673.27</p>
                </div>
                <div className={style.look}>
                    <p>24h Volume(USDT)</p>
                    <p>56,673,181,178.27</p>
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                    <img src={play}  style={{marginRight:11}}/>
                    <p style={{color: 'white'}}>Spot Tutorial</p>
                </div>
            </div>
        </div>
    )
}