import styled from '@emotion/styled'
import { List, MenuItem } from '@mui/material'
import notActive from '../notactive.png'
const Row = styled('div')(()=>({
    width:'100%',
    display:'flex',
    overflow:'auto'
}))
export default function MobileToolbar(){
    const data = [
            {id:1,img:notActive,title:'Favorites'},
            {id:2,title:'Spot Markets'},
            {id:3,title:'Futures Markets'},
            {id:4,title:'New listing'},
            {id:5,title:'All Cryptos'},
            {id:6,title:'Markets Overview'},
            {id:7,title:'Top Movers'},
    ]
    return (
        <Row>
                {
                    data.map(item=>{
                        return <MenuItem key={item.id}>{item.title}</MenuItem>
                    })
                }
        </Row>
    )
}