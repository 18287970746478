import style from './style.module.css'
import down from './Vector.png'
export default function ChartHead(){
    return(
        <div className={style.head}>
            <p>Dual investment - top 200 users to share $20.000 in rewards! (11-23)</p>
            <p>Dual investment - top 200 users to share $20.000 in rewards! (11-23)</p>
            <p>Dual investment - top 200 users to share $20.000 in rewards! (11-23)</p>
            <img src={down}/>
        </div>
    )
}