import img1 from './images/Vector 3.png'
import img2 from './images/Vector 4.png'
import img3 from './images/Vector 5.png'
import img4 from './images/Vector 6.png'
import img5 from './images/Vector 7.png'
import img6 from './images/Vector 8.png'
import img7 from './images/Vector 9.png'
import c2 from './images/image 12.png'
import c3 from './images/image 13.png'
import c4 from './images/image 14.png'
import c5 from './images/image 15.png'
import c6 from './images/image 17.png'
import c7 from './images/image 18.png'
import right from './images/right.png'
import style from './style.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
export default function Trend(){
    const matches = useMediaQuery('(max-width:1024px)');
    const history = useNavigate()
    const data = [
        {id:1,img:c2,name:'BNB',realName:'BNB',last_price:'645.23',change:12.14,market:img1},
        {id:2,img:c2,name:'CHZ',realName:'Chiliz',last_price:'645.23',change:-12.14,market:img2},
        {id:3,img:c3,name:'ETH',realName:'Ethereum',last_price:'645.23',change:12.14,market:img3},
        {id:4,img:c4,name:'BTC',realName:'Bitcoin',last_price:'645.23',change:12.14,market:img4},
        {id:5,img:c5,name:'SAND',realName:'The Sandbox',last_price:'645.23',change:-12.14,market:img5},
        {id:6,img:c6,name:'FIL',realName:'Filecoin',last_price:'645.23',change:12.14,market:img6},
        {id:7,img:c7,name:'SHIB',realName:'Filecoin',last_price:'645.23',change:12.14,market:img7},
    ]
    return(
        <div className={style.trend}>
        <div className={style.trend_table}>
            <h2>Market trend</h2>

            <table className={style.table}>
              <tr className={style.thead}>
                <th>Name</th>
                <th>Last Price</th>
                <th>24h Change</th>
                {!matches&&<th>Markets</th>}
              </tr>
              {
                  data.map(item=>{
                      return(
                        <tr key={item.id} onClick={()=>history('/chart')} style={{cursor:'pointer'}}>
                          <td>
                              <div style={{display:'flex', alignItems:'center'}}>
                                {item.id}. <img src={item.img} alt='table'/> {item.name} {item.realName}  
                              </div>
                            </td>
                          <td className={style.tbg}><div>$ {item.last_price}</div></td>
                          <td>{item.change}</td>
                          {!matches&&<td className={style.tbg}><div><img src={item.market} alt='table'/></div></td>}
                        </tr>
                      )
                  })
              }
         
            </table>
        </div>
        <Link to='/market'>View More Markets <img src={right} alt='right'/></Link>
        </div>
    )
}