import logo from './logo.svg';
import style from './App.module.css';
import ChartHead from './ChartHead/ChartHead';
import ContentHead from './ContentHead/ContentHead';
import t1 from './t1.png'
import t2 from './t2.png'
import t3 from './t3.png'
import dotes from './dotes.png'
import { data0, data1 } from './state';
import { BuildChart } from './BuildChart/BuildChart';
import TradeViewChart from "react-crypto-chart";
import Chart from 'kaktana-react-lightweight-charts'
import NewChart from './BuildChart/NewChart';
function App() {
  return (
    <div style={{background:'#0E110F'}}>
      <ChartHead/>
      <div className={style.content}>
          <div className={style.left_part}>
            <ContentHead/>
            <div style={{display:'flex'}}>
            <div className={style.left_column}>
              <div className={style.left_column_header}>
                <div className={style.icons}>
                  <img src={t1}/>
                  <img src={t2}/>
                  <img src={t3}/>
                </div>
                <div className={style.menu}>
                  <p>0.01</p>
                  <img src={dotes}/>
                </div>
              </div>
              <div className={style.left_column_content}>
                <table style={{color:'white'}}>
                  <tr style={{color:'#BCBCBC'}}>
                    <th>Price(USDT)</th>
                    <th>AMOUNT(BTC)</th>
                    <th>Total</th>
                  </tr>
                  {
                    data1.map((item,i)=>{
                      return(
                        <tr key={i}>
                          <td style={{color:'red'}}>{item.usdt}</td>
                          <td>{item.btc}</td>
                          <td>{item.total}</td>
                        </tr>
                      )
                    })
                  }
                </table>
                <br/>
                <br/>
                <table style={{color:'white'}}>
                  <tr style={{color:'#BCBCBC'}}>
                    <th>Price(USDT)</th>
                    <th>AMOUNT(BTC)</th>
                    <th>Total</th>
                  </tr>
                  {
                    data1.map((item,i)=>{
                      return(
                        <tr key={i}>
                          <td style={{color:'green'}}>{item.usdt}</td>
                          <td>{item.btc}</td>
                          <td>{item.total}</td>
                        </tr>
                      )
                    })
                  }
                </table>
              </div>
            </div>
            <div className={style.middle_column}>
              <NewChart/>
            </div>
            </div>
          </div>
          <div className={style.right_part}>

          </div>
      </div>
    </div>
  );
}

export default App;
