import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Grid, IconButton, MenuItem, Paper, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, NavLink } from "react-router-dom";
import style from "./style.module.css";
import logo from "../../images/logo.png";
import grid from "./grid.png";
import menu from "../../images/menu.png";

type Anchor = "right";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: "20px 110px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 4px 15px rgba(190, 190, 190, 0.25)",
}));

export default function MobileHeader() {
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
    const isMobile = useMediaQuery('(max-width:830px)');
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <MenuItem component={Link} to={"/sign-in"}>Login</MenuItem>
        <MenuItem component={Link} to={"/sign-up"}>Register</MenuItem>
        <MenuItem>Buy Crypto</MenuItem>
        <MenuItem>Markets</MenuItem>
        <MenuItem>Trade</MenuItem>
        <MenuItem>Derivatives</MenuItem>
        <MenuItem>Finance</MenuItem>
        <MenuItem>Downloads</MenuItem>
        <MenuItem>Eng</MenuItem>
        <MenuItem>USD</MenuItem>
      </List>
    </Box>
  );

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item>
                  <div className={style.nav}>
                    <img src={logo} alt="logo" />
                    <img src={grid} alt="menu" />
                  </div>
                  <IconButton onClick={toggleDrawer(anchor, true)}>
                    <img src={menu} alt="menu" />
                  </IconButton>
                </Item>
              </Grid>
            </Grid>
          </Box>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
