import Footer from "../../components/Footer/LargeFooter";
import MarketDashboard from "../../components/MarketDashboard/MarketDashboard";
import Table from "../../components/MarketDashboard/Table";
import Toolbar from "../../components/MarketDashboard/Toolbar";
export default function Market(){
    return(
        <div style={{marginBottom:225}}>
        <MarketDashboard/>
        <Toolbar/>
        <Table/>
        </div>
    )
}