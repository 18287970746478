import { Grid, Typography } from "@mui/material"
import support from './images/support.png'
import message from './images/message.png'
import blog from './images/blog.png'
import style from "./style.module.css"
export default function Support(){
    return(
        <div className={style.support}>
            <div>
            <Typography textAlign={'center'} variant={'h3'}>Get in touch. Stay in touch</Typography>
            <Grid container className={style.support_card}>
                <div>
                    <img src={support} alt='support'/>
                    <Typography style={{color:'black'}}>24 / 7 Support</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                </div>

                <div>
                    <img src={blog} alt='support'/>
                    <Typography>Tazeco Blog</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                </div>

                <div>
                    <img src={message} alt='support'/>
                    <Typography style={{color:'black'}}>Community</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                </div>
            </Grid>
        </div>
        </div>
    )
}