import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import LargeHeader from "./LargeHeader";
import MobileHeader from "./MobileHeader";

export default function Header(){
    const matches = useMediaQuery('(max-width:1500px)');
   
    return(
        <>
            {matches?<MobileHeader/>:<LargeHeader/>}
            <Outlet/>
        </>
    )
}