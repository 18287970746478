import styled from "@emotion/styled";
import { Checkbox, FormControlLabel, MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import style from './style.module.css'
import { Link } from "react-router-dom";
import { useState } from "react";
import rus from './rus.png'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Div = styled('div')({
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
    marginTop:75,
    width:'100%',
    height:'calc(100vh - 16vh)'
});

const SignBlock = styled('div')({
    display:'flex',
    flexDirection:'column',
});

const Choose = styled('div')({
    display:'flex',
});

const Item = styled('div')(({color}) => ({
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:205,
    height:50,
    borderBottom:color,
    cursor:'pointer',
    userSelect:'none'
}));

const Input = styled('input')(({width})=>({
    width: width,
    height: 50,
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
    paddingLeft:10,
    outline:'none',
    
}));

const Button = styled('button')({
    width: 410,
    height: 50,
    background: 'linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%)',
    color:'white',
    border:'none',
    cursor:'pointer'
});

const Backdrop = styled('div')({
    position: 'absolute',
    width: '100%',
    height: '100vh',
    left: 0,
    top: 0,
    background: 'rgba(116, 116, 116, 0.3)',
    backdropFilter: 'blur(15px)',
    zIndex:5,
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
})

const Modal = styled('div')({
    width: 500,
    height: 474,
    background: '#FFFFFF',
    boxShadow: "0px 5.18635px 38.8976px rgba(114, 114, 114, 0.25)",
    borderRadius: 7,
    padding:45
})

export default function Register({route}:any){
    const [select,setSelect] = useState('0')
    const [open, setOpen] = useState(true)
    const isMobile = useMediaQuery('(max-width:450px)') 
    return(
        <Div>
            {route==='register'&&open&&<Backdrop>
                <Modal>
                    <Typography marginBottom='20px'>Where do you live?</Typography>
                    <Typography marginBottom='50px'>Before we start, please enter your current location.</Typography>
                    <label>
                        <Typography marginBottom='8px'>Country/Area of Residence</Typography>
                        <Select className={style.country}>
                            <MenuItem><img src={rus}/>Russia</MenuItem>
                        </Select>
                    </label>
                        <Typography marginTop='60px'>The registration process is subject to change based on the information you provide.</Typography>
                    <div style={{display:'flex',justifyContent:'center', marginTop:60}}>
                        <Button style={{width:'100%'}} onClick={()=>setOpen(false)}>Confirm</Button>
                    </div>
                </Modal>
            </Backdrop>}
            <SignBlock>
                {route==='register'?<><Typography>Create Tezaco Account</Typography><Typography>Register with your Email or mobile</Typography></>:<Typography>Login</Typography>}
                <Choose>
                    <Item color={select==='0'?`3px solid #8AB399`:'3px solid #E0E0E0'} onClick={()=>setSelect('0')}>Email</Item>
                    <Item color={select==='1'?`3px solid #8AB399`:'3px solid #E0E0E0'} onClick={()=>setSelect('1')}>Mobile</Item> 
                </Choose>
                <label style={{marginTop:36}}>
                    <Typography>{select==='0'?'Email':'Mobile'}</Typography>
                    <Input width={!isMobile?410:'100%'}/>
                </label>
                <label style={{marginTop:36, marginBottom:36}}>
                    <Typography>Password</Typography>
                    <Input type='password' width={!isMobile?410:'100%'}/>
                </label>
                <div style={{display:"flex",flexDirection:'column'}}>
                   {route==='register'&&<>
                    <Typography marginBottom={'30px'}>Referral ID (Optional)</Typography>
                    <FormControlLabel
                      label="Subscribe to email updates"
                      control={<Checkbox
                          {...label}
                          sx={{
                            color: "#8AB399",
                            '&.Mui-checked': {
                              color: "#8AB399",
                            },
                          }}
                        />}
                    />

                    <FormControlLabel
                      label="I have read and agree to Binance’s Terms of Service"
                      control={<Checkbox
                          {...label}
                          sx={{
                            color: "#8AB399",
                            '&.Mui-checked': {
                              color: "#8AB399",
                            },
                          }}
                        />}
                    />
                    </>}

                    {
                        window.location.pathname==='/sign-up'?
                        <>
                            <Button style={{marginTop:30}}>Create Account</Button>
                            <p>Already registered? <Link to='/sign-in'>Log In</Link></p>
                        </>
                        :
                        <>
                            <Button style={{marginTop:30}}>Log in</Button>
                            <p>Don’t have an account? <Link to='/sign-up'>Register</Link></p>
                        </>
                    }
                </div>
                
            </SignBlock>
        </Div>
    )
}