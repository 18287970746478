import { Box, Grid, MenuItem, Paper, styled, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom';
import logo from '../../images/logo.png'
import down from './down.png'
import style from './style.module.css'
const Item = styled(Paper)(({ theme, padding }:any) => ({
    ...theme.typography.body2,
    padding: padding,
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    boxShadow: '0px 4px 15px rgba(190, 190, 190, 0.25)'
  }));



export default function SignPageHeader(){
  const isMobile =useMediaQuery('(max-width:700px)');
  console.log(isMobile);
  
    return(
        <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item padding={!isMobile?'20px 260px':'10px 20px'}>
                <div className={style.nav}>
                    <img src={logo} alt='logo'/>
                </div>
                <div className={style.nav}>
                    <MenuItem>Eng <img src={down} alt='menu'/></MenuItem>
                </div>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <Outlet/>
        </>
    )
}