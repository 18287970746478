import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./style.module.css";
import logo from "../../images/logo.png";
import btc from "./images/bitcoin.png";
import twt from "./images/twitter.png";
import someF from "./images/someface.png";
import tg from "./images/telegram.png";
import inst from "./images/insta.png";
import vk from "./images/vk.png";
import you from "./images/youtube.png";
import fb from "./images/facebook.png";

export default function MobileFooter() {
  return (
    <Box
      sx={{
        paddingBottom: 10,
        flexGrow: 1,
        background:
          "linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%)",
      }}
    >
      <Grid container spacing={4} className={style.cont}>
        <Grid item xs={12} marginBottom="90px">
          <div className={style.logo}>
            <img src={logo} alt="logo" />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Accordion style={{ background: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon htmlColor="white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>About Us</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>About Us</ListItem>
                <ListItem>Bisiness Contacts</ListItem>
                <ListItem>Community</ListItem>
                <ListItem>Tazeco Blog</ListItem>
                <ListItem>Terms</ListItem>
                <ListItem>Privacy</ListItem>
                <ListItem>News</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion style={{ background: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon htmlColor="white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Products</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>Exchange</ListItem>
                <ListItem>Academy</ListItem>
                <ListItem>Charity</ListItem>
                <ListItem>Card</ListItem>
                <ListItem>Labs</ListItem>
                <ListItem>Research</ListItem>
                <ListItem>Trust Wallet</ListItem>
                <ListItem>NFT</ListItem>
                <ListItem>Gift Card</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion style={{ background: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon htmlColor="white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Service</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>Downloads</ListItem>
                <ListItem>Buy Cripto</ListItem>
                <ListItem>Labs</ListItem>
                <ListItem>Research</ListItem>
                <ListItem>Trust Wallet</ListItem>
                <ListItem>NFT</ListItem>
                <ListItem>Gift Card</ListItem>
                <ListItem>Trust Wallet</ListItem>
                <ListItem>NFT</ListItem>
                <ListItem>Gift Card</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion style={{ background: "none", border: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon htmlColor="white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Support</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>Labs</ListItem>
                <ListItem>Research</ListItem>
                <ListItem>Trust Wallet</ListItem>
                <ListItem>NFT</ListItem>
                <ListItem>Gift Card</ListItem>
                <ListItem>Trust Wallet</ListItem>
                <ListItem>NFT</ListItem>
                <ListItem>Gift Card</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion style={{ background: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon htmlColor="white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Learn</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>Labs</ListItem>
                <ListItem>Research</ListItem>
                <ListItem>Trust Wallet</ListItem>
                <ListItem>NFT</ListItem>
                <ListItem>Gift Card</ListItem>
                <ListItem>Trust Wallet</ListItem>
                <ListItem>NFT</ListItem>
                <ListItem>Gift Card</ListItem>
                <ListItem>Labs</ListItem>
                <ListItem>Research</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ margin: 17.5, color: "white" }}>
            Community
          </Typography>
        </Grid>

        <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
          <div style={{margin:10}}>
            <img src={fb} alt="footer" />
          </div>
          <div style={{margin:10}}>
            <img src={twt} alt="footer" />
          </div>
          <div style={{margin:10}}>
            <img src={someF} alt="footer" />
          </div>
          <div style={{margin:10}}>
            <img src={inst} alt="footer" />
          </div>
        </div>

        <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
          <div style={{margin:10}}>
            <img src={tg} alt="footer" />
          </div>
          <div style={{margin:10}}>
            <img src={btc} alt="footer" />
          </div>
          <div style={{margin:10}}>
            <img src={you} alt="footer" />
          </div>
          <div style={{margin:10}}>
            <img src={vk} alt="footer" />
          </div>
        </div>
      </Grid>
    </Box>
  );
}
