const data = {
    login:{
        email:'',
        password:''
    },
    register:{
        email:'',
        
    }
}

export default function authReducer(state = data, action: { type: any; }){
    const temp = {...data}
        switch (action.type) {
            case "login":
                
                break;
        
            default:
                return temp
        }
}